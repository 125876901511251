<template>
  <el-main>
    <page-title />
    <div class="partition-area">
      <ics-search-inner :search-form="searchForm" more-btn="高级搜索" :fold-search.sync="showFoldSearch" @submit-search="search" @clear-search="clearSearch">
        <template>
          <el-col :span="6">
            <el-form-item label="用信编码">
              <el-input v-model="searchForm.useCode" placeholder="请输入要搜索的用信编码" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="授信编码">
              <el-input v-model="searchForm.creditCode" placeholder="请输入要搜索的授信编码" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="资金方">
              <el-input v-model="searchForm.capName" placeholder="请输入要搜索的资金方" />
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="状态">
              <el-select v-model="searchForm.flowStateCode" placeholder="请选择要搜索的状态" filterable>
                <el-option label="全部" value="" />
                <el-option v-for="item in constants.flowStateCode" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="6">
            <el-form-item label="创建人">
              <el-input v-model="searchForm.createdBy" placeholder="请输入要搜索的创建人" />
            </el-form-item>
          </el-col>
          <el-col v-show="showFoldSearch" :span="12">
            <el-form-item label="创建日期">
              <ics-search-date :date.sync="createdArr" />
            </el-form-item>
          </el-col>
        </template>
      </ics-search-inner>
    </div>
    <div class="partition-table">
      <ics-table-inner v-loading="loading.list" :column-option="columnOption" :table-data="tableList.list" :page-num="tableList.pageNum" :page-size="tableList.pageSize" :pages="tableList.pages" :hide-export-btn="true" @changePageNum="changePageNum">
        <template slot="btn-inner">
          <el-button v-loading="loading.export" type="primary" @click="exportFile">
            导出
          </el-button>
        </template>
        <template slot="table-columns-before">
          <el-table-column label="序号" type="index" :index="indexMethod" />
        </template>
        <template slot="table-columns-after">
          <el-table-column fixed="right" label="操作" width="120">
            <template v-slot="scope">
              <el-link v-if="scope.row.use.sonType === 1 && scope.row.use.flowStateCode === 'UseSupplement' && scope.row.pms.taskId !== ''" type="primary" class="text-btn" @click="$router.push({ name: 'useSupplement', params: { editMode: 'supplement' }, query: { useCode: scope.row.use.useCode, taskId: scope.row.pms.taskId } })">
                补件
              </el-link>
              <el-link v-if="scope.row.use.sonType === 2 && scope.row.use.sonCode && scope.row.pms.taskId !== ''" type="primary" class="text-btn" @click="useApprove(scope.row)">
                审批
              </el-link>
              <el-link v-if="scope.row.use.sonType === 1 && scope.row.use.flowStateCode === 'UseCore' && scope.row.pms.taskId !== ''" type="primary" class="text-btn" @click="$router.push({ name: 'useCoreAudit', params: { editMode: 'audit'}, query: { useCode: scope.row.use.useCode, taskId: scope.row.pms.taskId } })">
                审批
              </el-link>
              <el-link v-if="scope.row.use.sonType === 1 && scope.row.use.flowStateCode === 'UseAudit' && scope.row.pms.taskId !== ''" type="primary" class="text-btn" @click="$router.push({ name: 'useAudit', params: { editMode: 'audit' }, query: { useCode: scope.row.use.useCode, taskId: scope.row.pms.taskId } })">
                审批
              </el-link>
              <el-link v-if="scope.row.use.sonType === 1 && scope.row.use.method === 1 && scope.row.use.flowStateCode === 'UseAmount' && scope.row.pms.taskId !== ''" type="primary" class="text-btn" @click="$router.push({ name: 'useLoan', params: { editMode: 'loan' }, query: { useCode: scope.row.use.useCode, taskId: scope.row.pms.taskId } })">
                放款登记
              </el-link>
              <el-link v-if="scope.row.use.sonType === 1 && scope.row.use.method === 0 && scope.row.use.flowStateCode === 'UseAmount' && scope.row.pms.taskId !== ''" type="primary" class="text-btn" @click="$router.push({ name: 'useOnLineLoan', params: { editMode: 'loan' }, query: { useCode: scope.row.use.useCode, taskId: scope.row.pms.taskId } })">
                放款登记
              </el-link>
              <el-link type="primary" class="text-btn" @click="$router.push({ name: 'useDetail', query: { useCode: scope.row.use.useCode } })">
                查看
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ics-table-inner>
    </div>
    <ics-business-audit-inner :dialog="dialog.audit" :loading-submit="loadingSubmit" @submit="submitAudit" />
  </el-main>
</template>

<script>
import { basePageListMixin } from '@/assets/js/mixins'
import IcsBusinessAuditInner from '@/components/business-audit-inner'
import utils from '@/assets/js/utils'
export default {
  components: { IcsBusinessAuditInner },
  mixins: [basePageListMixin],
  data () {
    return {
      showFoldSearch: false,
      baseSearchForm: {
        useCode: '',
        creditCode: '',
        capName: '',
        flowStateCode: '',
        createdBy: '',
        createdStart: '',
        createdEnd: ''
      },
      auditInfo: {},
      dialog: {
        audit: {
          visible: false,
          title: '审批用信'
        }
      },
      loadingSubmit: {
        submit: false
      },
      columnOption: [
        { label: '用信编码', prop: 'use.useCode', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '授信编码', prop: 'use.creditCode', formatter: this.utils.isEffective, minWidth: 180 },
        { label: '融资企业', prop: 'use.customerName', formatter: this.utils.isEffective, minWidth: 180 },
        { label: '资金方', prop: 'use.capName', formatter: this.utils.isEffective, minWidth: 180 },
        { label: '统一社会信用代码', prop: 'use.customerSocialNo', formatter: this.utils.isEffective, minWidth: 180 },
        { label: '用信额度(元)', prop: 'use', formatter: (row, col, cell) => this.utils.applyTableMoneyFormat(cell), minWidth: 100 },
        { label: '创建人', prop: 'use.createdBy', formatter: this.utils.isEffective, minWidth: 180 },
        { label: '创建时间', prop: 'use.createdAt', formatter: this.utils.isEffective, minWidth: 140 },
        { label: '状态', prop: 'use.flowStateName', formatter: this.utils.isEffective, minWidth: 100 },
      ]
    }
  },
  computed: {
    createdArr: utils.computedDate('createdStart', 'createdEnd')
  },
  methods: {
    getList () {
      this.loading.list = true
      this.api.use.pageList(this.utils.filterTrimValue(this.searchForm)).then(result => {
        this.tableList = result.data.data
      }).finally(() => {
        this.loading.list = false
      })
    },
    exportFile () {
      this.loading.export = true
      this.api.use.exportFile(this.searchForm).then(result => this.utils.exportFile(result)).finally(() => {
        this.loading.export = false
      })
    },
    useApprove (row) {
      this.auditInfo = row || {}
      this.dialog.audit.visible = true
    },
    submitAudit (formData) {
      return new Promise((resolve, reject) => {
        this.loadingSubmit.submit = true
        let data = formData || {}
        this.api.use.complete(this.auditInfo.pms.taskId, data).then(result => {
          if (result.data.success === true) {
            this.loadingSubmit.submit = false
            this.$message.success('操作成功')
            this.dialog.audit.visible = false
            this.getList()
          } else {
            this.$message.error(result.data.message)
          }
          resolve(result.data.data)
        }).catch(e => {
          reject(e)
        }).finally(() => {
          this.loadingSubmit.submit = false
        })
      })
    }
  }
}
</script>

<style>

</style>
